// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { EmissionFootprint } from '../assessment/assessment.model';
import { AuthService } from '../core/auth/auth.service';
import { EntityService } from '../core/entity/entity.service';
import { ModeDefinitions } from '../core/mode/mode.definition';
import { Questionnaire } from '../questionnaire/questionnaire.model';
import { AlertService } from '../shared/alert.service';
import { User } from '../user/user.model';
import ClientMode from './client-mode.model';
import { Client } from './client.model';

export interface Preferences {
  completed: boolean;
  preferences: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class ClientService extends EntityService<Client> {
  readonly footprintType = 'live';

  readonly selectedClientId$ = new BehaviorSubject<number>(getClientIdFromLastSession());

  selectedClient$ = this.selectedClientId$.pipe(
    distinctUntilChanged(),
    switchMap(clientId =>
      this.authService.userProfile$.pipe(
        // Only emit selected value if the user is part of the selected client.
        // This is not a security check, it just improves the UX.
        switchMap(userProfile => this.getAllByUser$(userProfile.userId)),
        map(clients => clients.find(c => c.id === clientId) ?? clients[0] ?? null),
        filter(client => client !== null),
        map(client => client.id),
      ),
    ),
    switchMap((clientId: number) => this.getOne$(clientId).pipe(first())),
    tap(client => {
      if (client.id != null) {
        storeClientIdForNextSession(client.id);
      }
    }),
    shareReplay(1),
  );

  clientActiveModes$: Observable<ClientMode[]> = this.selectedClient$.pipe(
    map(client => {
      const modesWithActive = ModeDefinitions.modes.map(m => ({
        mode: m,
        active: client.enabledAssessmentModes.includes(m),
      }));
      return modesWithActive.filter(m => ModeDefinitions.isMainMode(m.mode) || m.active);
    }),
  );

  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
    protected readonly authService: AuthService,
  ) {
    super(alertService, httpClient);
  }

  getAllByUser$(userId: number): Observable<Client[]> {
    return super.getAll$({ user_id: userId.toString() });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getScopeEmissions$(id: number, params: any = {}): Observable<EmissionFootprint> {
    const url = this.getWebApiEndpoint('scope-emissions', id);
    this.validateParameters(params);

    const req$ = this.httpClient.get<EmissionFootprint>(url, { params });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get scope emissions by ${this.singularIdentifier()}(${id}) failed:`,
      nice: `Failed to get scope emissions by ${this.singularIdentifier()}`,
    });
  }

  getAllByClassifier$(classifierId: number): Observable<Client[]> {
    return super.getAll$({ classifier_id: classifierId.toString() });
  }

  getClassifiersByClient$(clientId: number) {
    const url = this.getWebApiEndpoint('classifiers', clientId);
    const req$ = this.httpClient.get<User[]>(url);

    return this.handleGetOrCreateResponse(req$, {
      log: `Get classifiers by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to get classifiers by ${this.singularIdentifier()}`,
    });
  }

  updateClassifiersByClient$(clientId: number, classifiers: User[]) {
    const url = this.getWebApiEndpoint('classifiers', clientId);
    const req$ = this.httpClient.put<Client>(url, { classifiers });

    return this.handleUpdateOrDeleteResponse(req$, {
      log: `Update classifiers by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to update classifiers ${this.singularIdentifier()}`,
    });
  }

  getQuestionnairesByClient$(clientId: number, params: Record<string, string | number> = {}) {
    const url = this.getWebApiEndpoint('questionnaires', clientId);
    const req$ = this.httpClient.get<Questionnaire[]>(url, { params });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get questionnaires by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to get questionnaires by ${this.singularIdentifier()}`,
    });
  }

  updateQuestionnairesByClient$(clientId: number, questionnaires: Questionnaire[]) {
    const url = this.getWebApiEndpoint('questionnaires', clientId);
    const req$ = this.httpClient.put<Client>(url, { questionnaires });

    return this.handleUpdateOrDeleteResponse(req$, {
      log: `Update questionnaires by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to update questionnaires ${this.singularIdentifier()}`,
    });
  }

  getFirstByUser$(userId: number): Observable<Client> {
    return this.getAllByUser$(userId).pipe(map((clients: Client[]) => clients[0]));
  }

  setSelectedClient(clientId: number) {
    this.selectedClientId$.next(clientId);
  }

  updatePreferences$(clientId: number, payload: Preferences) {
    const url = this.getWebApiEndpoint('preferences', clientId);
    const req$ = this.httpClient.put<Preferences>(url, { payload });

    return this.handleUpdateOrDeleteResponse(req$, {
      log: `Update preferences by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to update preferences for ${this.singularIdentifier()}`,
    });
  }

  resetPreferences$(clientId: number) {
    const url = this.getWebApiEndpoint('preferences', clientId);
    const req$ = this.httpClient.put<Preferences>(url, { payload: null });

    return this.handleUpdateOrDeleteResponse(req$, {
      log: `Reset preferences by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to reset preferences for ${this.singularIdentifier()}`,
    });
  }

  retrievePreferences$(clientId: number) {
    const url = this.getWebApiEndpoint('preferences', clientId);
    const req$ = this.httpClient.get<Preferences>(url);

    return this.handleGetOrCreateResponse(req$, {
      log: `Get preferences by ${this.singularIdentifier()}(${clientId}) failed:`,
      nice: `Failed to get preferences for ${this.singularIdentifier()}`,
    });
  }

  pluralIdentifier(): string {
    return 'clients';
  }

  singularIdentifier(): string {
    return 'client';
  }
}

const CLIENT_ID_KEY = 'clientId';
const getClientIdFromLastSession = () => {
  const storedId = sessionStorage.getItem(CLIENT_ID_KEY);
  if (!storedId) {
    return null;
  }
  try {
    return Number(storedId);
  } catch {
    return null;
  }
};
const storeClientIdForNextSession = (id: number) => sessionStorage.setItem(CLIENT_ID_KEY, id.toString());
