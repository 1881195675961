// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AnalyticsEvent, AnalyticsService } from 'src/app/shared/analytics.service';
import { NotAuthorisedModalComponent } from 'src/app/ui/modal/not-authorised-modal/not-authorised-modal.component';
import { ServerErrorModalComponent } from 'src/app/ui/modal/server-error-modal/server-error-modal.component';

import { isDemoSite } from 'src/app/shared/util';
import { AuthService } from './auth.service';

export const tokenInterceptorFn: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const modalService = inject(BsModalService);
  const analyticsService = inject(AnalyticsService);

  if (request.url.includes('/api/')) {
    const urlToken = authService.getUrlToken();
    if (urlToken) {
      return runWithUrlToken(request, urlToken, next, authService, modalService, analyticsService);
    } else {
      return runWithAccessToken(request, next, authService, modalService, analyticsService);
    }
  } else {
    // Looks like an request that doesn't need an access token, try without any
    return next(request);
  }
};

function runWithUrlToken(
  request: HttpRequest<unknown>,
  urlToken: string,
  next: HttpHandlerFn,
  authService: AuthService,
  modalService: BsModalService,
  analyticsService: AnalyticsService,
) {
  const requestWithUrlToken = request.clone({
    setHeaders: { Authorization: `Bearer ${urlToken}` },
  });

  return next(requestWithUrlToken).pipe(
    catchError(error => runWithAccessToken(request, next, authService, modalService, analyticsService)),
  );
}

function runWithAccessToken(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthService,
  modalService: BsModalService,
  analyticsService: AnalyticsService,
) {
  return authService.getAccessToken$().pipe(
    mergeMap(token => {
      const requestWithToken = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });

      return next(requestWithToken);
    }),
    catchError(error => {
      // 1. If first attempt is Forbidden 403, try again with a fresh access token.
      //   * This would address any outdated access tokens with old permissions following an API update.
      // 2. If first attempt is NotAuthorised 401, try again with a fresh access token.
      //   * Access token likely has expired and we need to get a new access token from Auth0.
      if (error.status === 403 || error.status === 401) {
        return authService.getAccessToken$({ cacheMode: 'off' }).pipe(
          mergeMap(token => {
            const requestWithToken = request.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
            return next(requestWithToken);
          }),
          catchError(error2 => handleError(error2, modalService, analyticsService)),
        );
      } else if (error.status === 418) {
        return authService.logout$(window.location.origin, false);
      } else {
        // Need to return thrown error observable as there are `catchError()`s further down the chain that need to handle the error.
        return handleError(error, modalService, analyticsService);
      }
    }),
  );
}

function handleError(
  resp: HttpErrorResponse,
  modalService: BsModalService,
  analyticsService: AnalyticsService,
): Observable<never> {
  // Find the appropriate error message to display
  const message = resp.statusText || resp.message || (resp.error?.error ?? resp.error) || 'Error';

  if (resp.status === 401) {
    const modalRef = modalService.show(NotAuthorisedModalComponent);
    if (isDemoSite) {
      modalRef.content.title = "Sorry, you can't perform that action with this demo.";
    }
    return EMPTY;
  }

  // status = 0 : "Unknown Error". API has not responded (timed out, server provided no response)
  if (resp.status === 0) {
    modalService.show(ServerErrorModalComponent);
    return EMPTY;
  }

  // Track handled error
  analyticsService.track(AnalyticsEvent.HANDLED_ERROR, { error: message });

  // Throw the error, there may be required behaviours further down the chain. e.g. on 404 error GET question response, then POST to create question response.
  return throwError(() => resp);
}
